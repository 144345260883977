import { Paper, Typography, Box } from "@mui/material";
import { Container } from "@mui/system";

const Rules = () => {
  return (
    <Container maxWidth='xl'>
      <Paper elevation={6}>
        <Typography
          variant='h4'
          paddingTop={5}>
          Ограничения на содержимое рекламных публикаций
        </Typography>

        <Box sx={{ padding: 5 }}>
          <Typography
            align='justify'
            paragraph
            variant='p'>
            Ниже мы собрали адаптированный список объектов, запрещённых к
            рекламированию законом, а также сомнительных по мнению администрации
            ВК креативов. Запрещена реклама:
          </Typography>
          <Typography
            align='justify'
            paragraph>
            <ol start='1'>
              <li>С использованием креативов, содержащих:</li>
            </ol>
            <ul>
              <li>
                <strong>Завышенные неподкреплённые обещания</strong>:
                <em>«Узнай, с кем тебе изменяет твой партнёр»</em>,
                <em>«Зарабатывай миллион в неделю благодаря опционам»</em>.
              </li>
            </ul>
            <ul>
              <li>
                <strong>Незаконченные истории</strong> с целью увода на сайт
                продвигаемой услуги или продукта:
                <em>
                  «Екатерина: За сутки уходило до 3-х кг жиpa! Мешала в стакане
                  1 ложку соды и дешёвые... Читать далee: (ссылка на сайт
                  средства для похудения)».
                </em>
              </li>
            </ul>
            <ul>
              <li>
                Искажение сути новостей <strong>путем недосказанности,</strong>{" "}
                со спекуляцией на теме <strong>трагических событий</strong>{" "}
                (убийство, самоубийство, смерть, теракт), а также с{" "}
                <strong>провокационным содержимым</strong>, вводящим в
                заблуждение или призванным шокировать пользователей.
              </li>
            </ul>
            <ul>
              <li>
                Пошлые и неэстетические изображения, шок-контент, порнографию,
                темы детской сексуальности, любые сексуальные фетиши,
                откровенную эротику, включая рисованную, а также любые другие
                завлечения, использующие{" "}
                <strong>тематику секса и сексуальности</strong>.
              </li>
            </ul>
            <ul>
              <li>
                Темы <strong>физических недостатков</strong>, особенностей
                строения тела, физиологических процессов и заболеваний, а также
                использующих непривлекательные и неэстетические изображения,
                включая рисованные.
              </li>
            </ul>
            <ul>
              <li>
                <strong>Продолжение истории</strong>, если это продолжение и
                конечный рекламный объект не доступны сразу и беспрепятственно и
                в один клик.
              </li>
            </ul>
            <ul>
              <li>
                Неуместный или нетематический контент
                (текст/видеозаписи/фотографии),
                <strong> не относящийся напрямую</strong> к объекту рекламы;
              </li>
            </ul>
            <ol start='2'>
              <li>
                С продажей товаров, очевидно не соответствующих по соотношению
                «цена-качество» заявленным критериям, подделок под известные
                марки без указания, что это реплика, несуществующих товаров,
                являющихся вымыслом продавцов (
                <a
                  target='_blank'
                  rel='noreferrer'
                  href='https://vk.com/wall-59800369_141593'
                  style={{ color: "#447BBA" }}>
                  подробнее
                </a>
                ).
              </li>
              <li>
                Розыгрышей с нарушениями правил проведения конкурсов и акций;
              </li>
              <li>
                Сайтов, <strong>подделывающих интерфейс </strong>
                других интернет-ресурсов с целью повышения привлекательности
                продвигаемых товаров или услуг: сайтов новостей, телеканалов,
                журналов, политических ведомств и подобного, в том числе виджеты
                социальных сетей;
              </li>
              <li>
                Услуг <strong>«накруток»</strong> для ВКонтакте и других
                социальных сетей и сайтов,
                <strong> взлома</strong> (сайтов, почты, личных страниц) и услуг
                по
                <strong> рассылке спама;</strong>
              </li>
              <li>
                Сайтов с<strong> вирусами и SMS-мошенничеством</strong>;
              </li>
              <li>
                <strong>Алкоголя, табака и никотиновых жидкостей</strong>:
                продвижение конкретных брендов и реклама дистанционной торговли.
                Исключение — магазины с алкоголем, не торгующие дистанционно, и
                заведения (бары, рестораны), где продают алкоголь;
              </li>
              <li>
                <strong>Наркотических веществ</strong>;
              </li>
              <li>
                <strong>Казино</strong> и
                <strong>других видов азартных игр</strong>, в том числе включая
                тотализаторы, букмекеров, ставки на спорт (и киберспорт), а
                также связанные с ними услуги прогнозирования.
              </li>
            </ol>
            <p>
              <em>
                Исключение — покер. Следуя международному развитию и тенденциям
                игры, наша площадка признаёт покер интеллектуально-коммерческой
                игрой, результат которой зависит от умственных способностей
                игрока, логического мышления, опыта и профессионализма. Поэтому
                реклама покера не должна:
              </em>
            </p>
            <ul>
              <li>
                <em>
                  — содержать утверждения, которые преувеличивают вероятность
                  получения выигрыша;
                </em>
              </li>
            </ul>
            <ul>
              <li>
                <em>
                  — создавать впечатление того, что получение выигрыша
                  гарантировано или что участие в интеллектуально-коммерческих
                  играх является способом получения средств к существованию.
                </em>
              </li>
            </ul>
            <ul>
              <li>
                <em>
                  Вне зависимости от позиции нашей площадки, если сообщество
                  будет нарушать законодательство в сфере организации и
                  проведения азартных игр в интернете и надзорные органы
                  направят нам запрос на блокировку, мы будем вынуждены принять
                  меры.
                </em>
              </li>
            </ul>
            <ol start='10'>
              <li>
                <strong>Интимных услуг</strong> (включая виртуальный секс),
                продажи личных интимных фото- и видеоматериалов;
              </li>
              <li>
                <strong>Интимных товаров</strong> сомнительного действия
                (средств для увеличения полового члена или груди, таблеток для
                продления оргазма, стимулирующих полового партнёра к сексу
                средств и подобное).
              </li>
              <li>
                Услуг по подготовке и написанию
                <strong> выпускных квалификационных работ</strong>, научных
                докладов об основных результатах подготовленных
                научно-квалификационных работ (диссертаций) и иных работ,
                предусмотренных государственной системой научной аттестации или
                необходимых для прохождения обучающимися промежуточной или
                итоговой аттестации;
              </li>
              <li>
                Продажи<strong> документов</strong>;
              </li>
              <li>
                <strong>
                  Незаконной торговли валютой, инвестиции и заработки;
                </strong>
              </li>
              <li>
                <strong>Политическая реклама;</strong>
              </li>
              <li>
                <strong>Лекарственных средств</strong>,
                <strong> медицинской техники</strong>,
                <strong> изделий медицинского назначения </strong>
                (за исключением некоторых методов контрацепции) и
                <strong> медицинских услуг</strong>, в том числе методов
                лечения, без привязки дисклеймера о возможных противопоказаниях
                и необходимости консультации со специалистом;
              </li>
              <li>
                <strong>Биологически активных добавок</strong>, препаратов и
                средств, которые позиционируют себя как БАД, а также схожих
                товаров, если в рекламе присутствуют следующие креативы:
                <br />— фотографии до и после приёма препарата;
                <br />— формулировки, которые предполагают или намекают на
                наличие проблем со здоровьем у пользователя;
                <br />— шокирующие и эстетически неприемлемые изображения;
                <br />— обещания каких-либо эффектов, если речь идет о средстве,
                которое принимается внутрь, а также ввод в заблуждение
                относительно свойств и характеристик товара;
                <br />— конкретные случаи излечения людей, улучшения их
                состояния в результате применения БАД (или схожего товара);
                <br />— фотографии медицинского персонала, тем самым вводящие в
                заблуждение, что это не БАД (или схожий товар), а
                рекомендованное медициной лекарство;
                <br />— благодарность физическим лицам, которые помогли найти
                тот или иной БАД (или схожий товар).
              </li>
            </ol>
          </Typography>
          <Typography
            variant='h4'
            marginBottom={2}>
            Правила размещения товаров и услуг
          </Typography>

          <Typography
            align='justify'
            paragraph>
            <ol start='1'>
              <li>Продаваемые услуги и товары должны соответствовать:</li>
            </ol>
            <ul>
              <li>
                <a
                  target='_blank'
                  rel='noreferrer'
                  href='https://vk.com/terms'
                  style={{ color: "#447BBA" }}>
                  Правилам пользования сайтом ВКонтакте
                </a>
                .
              </li>
              <li>
                Законодательству РФ, в частности
                <a
                  target='_blank'
                  rel='noreferrer'
                  href='http://docs.pravo.ru/document/view/14222658'
                  title='http://docs.pravo.ru/document/view/14222658'
                  style={{ color: "#447BBA" }}>
                  Правилам продажи товаров дистанционным способом
                </a>{" "}
                и{" "}
                <a
                  target='_blank'
                  rel='noreferrer'
                  href='http://docs.pravo.ru/document/view/5149690'
                  title='http://docs.pravo.ru/document/view/5149690'
                  style={{ color: "#447BBA" }}>
                  Закону РФ от 07.02.1992 № 2300-1 «О защите прав потребителей»
                </a>
                .
              </li>
            </ul>
            <ol start='2'>
              <li>В сообществе должны быть указаны:</li>
            </ol>
            <ul>
              <li>
                Информация о правилах оплаты, доставки и процедуре возврата
                товаров.
              </li>
              <li>
                Контактные данные организации или продавца, а также телефон или
                email поддержки.
              </li>
              <li>Юридическим лицам необходимо указать свой адрес.</li>
            </ul>
            <ol start='3'>
              <li>Запрещается оплата через SMS-платежи и подписки.</li>
              <li>
                Через товары ВКонтакте{" "}
                <strong>запрещается распространять</strong>:
              </li>
            </ol>
            <ul>
              <li>
                Услуги, связанные с деятельностью, запрещённой администрацией
                ВКонтакте. Взлом страниц, накрутка (лайков, подписчиков,
                репостов, просмотров), спам-рассылки, продажа голосов и прочего.
                Любые программы, автоматизированные скрипты для сбора информации
                на сайте или взаимодействия с сайтом.
              </li>
              <li>
                Любые лекарственные средства, медицинские товары и технику,
                изделия медицинского назначения, медицинские услуги и услуги
                оздоровительного характера.
              </li>
              <li>
                Любые услуги экстрасенсов, целителей, ясновидящих,
                предсказателей, «черных» и «белых» магов.
              </li>
              <li>
                Любую алкогольную{" "}
                <a
                  href='#1'
                  style={{ color: "#447BBA" }}>
                  [1]
                </a>{" "}
                (в том числе пиво) и табачную продукцию{" "}
                <a
                  href='#2'
                  style={{ color: "#447BBA" }}>
                  [2]
                </a>
                , курительные принадлежности (в том числе электронные сигареты),
                за исключением аксессуаров, не являющихся напрямую частью
                прибора для курения (зарядные устройства, чехлы для вейпов,
                чистящие салфетки){" "}
                <a
                  href='#3'
                  style={{ color: "#447BBA" }}>
                  [3]
                </a>
                , услуги компаний, занимающихся производством такой продукции.
              </li>
              <li>
                Интимные тренинги, стриптиз-клубы, эротический массаж, средства
                для повышения потенции и аналогичные средства, эскорт-услуги и
                прочее{" "}
                <a
                  href='#4'
                  style={{ color: "#447BBA" }}>
                  [4]
                </a>
                .
              </li>
              <li>
                Анаболические стероиды, иные препараты, специализированные
                пищевые продукты, воздействующие на рост мышц, действие которых
                аналогично действию стероидов, а также БАДы.
              </li>
              <li>
                Оружие (военное и гражданское), в том числе оружие самообороны,
                спортивное, охотничье и сигнальное оружие.
              </li>
              <li>Порнографические материалы.</li>
              <li>
                «Цифровые наркотики» и курительные смеси, соли, попперсы,
                веселящие газы и любые психотропные вещества.
              </li>
              <li>
                Услуги вебкам-студий и другие услуги, связанные с
                предоставлением видеообщения в интимных целях.
              </li>
              <li>Коллекторские услуги.</li>
              <li>
                Товары, тематика которых кардинально расходится с тематикой
                сообщества.
              </li>
            </ul>
            <ul>
              <li>
                <em>
                  Пример 1: сообщество о велогонках не может предлагать
                  пользователям приобретать мягкие игрушки, если только сами
                  игрушки не являются их брендированной продукцией.
                </em>
              </li>
            </ul>
            <ul>
              <li>
                <em>
                  Пример 2: сообщество, посвященное строительству, может
                  разместить подборку инструментов на продажу.
                </em>
              </li>
            </ul>
            <ul>
              <li>
                Ссылки на сторонние ресурсы, не имеющие отношения к товару.
              </li>
              <li>
                Рекламу и места под рекламу в Вашем сообществе. Исключение —
                размещение информации о продаже рекламы (рекламный прайс) в
                сообществе в разделе «<strong>Услуги</strong>».
              </li>
            </ul>
            <ol start='5'>
              <li>
                Администрация ВКонтакте вправе по своему выбору удалить карточку
                товара в разделе «Товары» Сообщества или отключить раздел
                «Товары» Сообщества в случае нарушения настоящих Правил и/или в
                случае, если товары магазина не соответствуют политике, взглядам
                и убеждениям администрации сайта.{" "}
                <a
                  href='#5'
                  style={{ color: "#447BBA" }}>
                  [5]
                </a>
              </li>
            </ol>
            <p>
              <strong>Примечания</strong>
            </p>
            <p>
              <a
                name='1'
                href='/#'>
                {" "}
              </a>
              [1] – питьевой этиловый спирт, спиртные напитки (в т.ч. водка),
              вино (в т.ч. натуральное вино); пиво и напитки, изготавливаемые на
              его основе, а также любые слабоалкогольные напитки.
            </p>

            <p>
              <a
                name='2'
                href='/#'>
                {" "}
              </a>
              [2] – табак, сырье для производства табачных изделий, табачные
              изделия в целом, а также различные виды табачных изделий, как
              курительные – сигареты, сигары, сигариллы (сигариты), папиросы,
              табак для кальяна, табак курительный, в т.ч. тонкорезаный, махорка
              – крупка курительная, табак трубочный, биди, кретек, так и
              некурительные – табак сосательный (снюс), табак жевательный, табак
              нюхательный, насвай; другие табачные изделия, различные
              ингредиенты табачных изделий.
            </p>

            <p>
              <a
                name='3'
                href='/#'>
                {" "}
              </a>
              [3] – курительные принадлежности, в том числе электронные
              сигареты, трубки, кальяны; нетабачные материалы, придающие
              табачному изделию установленные изготовителем характеристики,
              особенности и форму – папиросная, сигаретная, ободковая и
              мундштучная бумага, оберточная бумага для фильтров (фицелла),
              фильтрующий материал, клеи, чернила, оберточный материал для
              порции табака сосательного (снюса).
            </p>

            <p>
              <a
                name='4'
                href='/#'>
                {" "}
              </a>
              [4] Размещение товаров для взрослых (интим-товары, презервативы)
              допустимо, если в сообществе установлено возрастное ограничение
              18+ и сообщество специализируется на продаже товаров для взрослых.
            </p>

            <p>
              <a
                name='5'
                href='/#'>
                {" "}
              </a>
              [5] – в числе прочего: в случаях, если предлагаются непроверенные,
              сомнительные товары, работы или услуги; товары и услуги, которые
              по личному мнению администрации потенциально могут нарушать права,
              причинить вред или угрожать безопасности пользователей или третьих
              лиц.
            </p>
          </Typography>
        </Box>
      </Paper>
    </Container>
  );
};

export default Rules;
